export const light = {
  alternate: {
    main: '#e0f2e9',
    dark: '#b2dfdb',
  },
  cardShadow: 'rgba(23, 70, 161, .11)',
  mode: 'light',
  primary: {
    main: '#00AB9D',//'#28a745', // Bright Green
    light: '#80e27e', // Lighter Green
    dark: '#007b33', // Darker Green
    contrastText: '#fff',
  },
  secondary: {
    light: '#a5d6a7', // Light Green
    main:  '#66bb6a', // Medium Green
    dark: '#00AB9D',//'#388e3c', // Dark Green
    contrastText: 'rgba(0, 0, 0, 0.87)',
  },
  text: {
    primary: '#1e2022',
    secondary: '#677788',
  },
  divider: 'rgba(0, 0, 0, 0.12)',
  background: {
    paper: '#ffffff',
    default: '#ffffff',
    level2: '#f5f5f5',
    level1: '#ffffff',
  },
};

export const dark = {
  alternate: {
    main: '#1a2138',
    dark: '#151a30',
  },
  cardShadow: 'rgba(0, 0, 0, .11)',
  common: {
    black: '#000',
    white: '#fff',
  },
  mode: 'dark',
  primary: {
    main: '#00AB9D',//'#28a745', // Bright Green
    light: '#80e27e', // Lighter Green
    dark: '#007b33', // Darker Green
    contrastText: '#fff',
  },
  secondary: {
    light: '#a5d6a7', // Light Green
    main: '#66bb6a', // Medium Green
    dark: '#00AB9D',//'#388e3c', // Dark Green
    contrastText: 'rgba(0, 0, 0, 0.87)',
  },
  text: {
    primary: '#EEEEEF',
    secondary: '#AEB0B4',
  },
  divider: 'rgba(255, 255, 255, 0.12)',
  background: {
    paper: '#222B45',
    default: '#222B45',
    level2: '#333',
    level1: '#2D3748',
  },
};



// export const light = {
//   alternate: {
//     main: '#f7faff',
//     dark: '#edf1f7',
//   },
//   cardShadow: 'rgba(23, 70, 161, .11)',
//   mode: 'light',
//   primary: {
//     main: '#3BB143',
//     //'#377dff',
//     light: '#00ff00',
//     //'#467de3',
//     dark: '#00ff00',
//     //'#2f6ad91',
//     contrastText: '#fff',
//   },
//   secondary: {
//     light: '#ffb74d',
//     //'#ffb74d',
//     main: '#98fb00',
//     //'#f9b934',
//     dark: '#00ff00',
//     //'#FF9800',
//     contrastText: 'rgba(0, 0, 0, 0.87)',
//   },
//   text: {
//     primary: '#1e2022',
//     secondary: '#677788',
//   },
//   divider: 'rgba(0, 0, 0, 0.12)',
//   background: {
//     paper: '#ffffff',
//     default: '#ffffff',
//     level2: '#f5f5f5',
//     level1: '#ffffff',
//   },
// };

// export const dark = {
//   alternate: {
//     main: '#1a2138',
//     dark: '#151a30',
//   },
//   cardShadow: 'rgba(0, 0, 0, .11)',
//   common: {
//     black: '#000',
//     white: '#fff',
//   },
//   mode: 'dark',
//   primary: {
//     main: '#3BB143', //'#1976d2',
//     light: '#2196f3',
//     dark: '#0d47a1',
//     contrastText: '#fff',
//   },
//   secondary: {
//     light: '#FFEA41',
//     main: '#00ff00',//'#FFE102',
//     dark: '#DBBE01',
//     contrastText: 'rgba(0, 0, 0, 0.87)',
//   },
//   text: {
//     primary: '#EEEEEF',
//     secondary: '#AEB0B4',
//   },
//   divider: 'rgba(255, 255, 255, 0.12)',
//   background: {
//     paper: '#222B45',
//     default: '#222B45',
//     level2: '#333',
//     level1: '#2D3748',
//   },
// };
