import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import Page from '../../src/components/Page';

import 'react-lazy-load-image-component/src/effects/blur.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-image-lightbox/style.css';
import 'aos/dist/aos.css';
import { Image } from '@mui/icons-material';

export default function TopLayout(props) {
  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <link
          rel="shortcut icon"
          href="https://fg-life-assets.s3.ap-southeast-2.amazonaws.com/favicon.ico"
          //href="https://fg-life-assets.s3.ap-southeast-2.amazonaws.com/fglife.ico"
          // href="https://assets.maccarianagency.com/favicons/thefront/favicon.ico"
          // https://disha-pharma-assets.s3.ap-south-1.amazonaws.com/disha-ico.ico
        />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <title>FGLife | A wise choice</title>
        <meta name="theme-color" content="green" />
        <meta
          name="description"
          content="A modern pharma dealer for your storage and distribution."
        />
        <meta
          name="robots"
          content="max-snippet:-1, max-image-preview:large, max-video-preview:-1"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://fg-life-assets.s3.ap-southeast-2.amazonaws.com/photo-for-insurance1.jpg"
        />
        <meta property="og:title" content="FGLife | A wise choice" />
        <meta
          property="og:description"
          content="A insurance agency for when life happens."
        />
        <meta property="og:url" content="https://fglife.co.nz/" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Inter:wght@400;600&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <Page>{props.children}</Page>
    </React.Fragment>
  );
}

TopLayout.propTypes = {
  children: PropTypes.node,
};
