exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-claims-js": () => import("./../../../src/pages/claims.js" /* webpackChunkName: "component---src-pages-claims-js" */),
  "component---src-pages-coldstorage-jsx": () => import("./../../../src/pages/coldstorage.jsx" /* webpackChunkName: "component---src-pages-coldstorage-jsx" */),
  "component---src-pages-complaint-js": () => import("./../../../src/pages/complaint.js" /* webpackChunkName: "component---src-pages-complaint-js" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-disclaimer-js": () => import("./../../../src/pages/disclaimer.js" /* webpackChunkName: "component---src-pages-disclaimer-js" */),
  "component---src-pages-health-js": () => import("./../../../src/pages/health.js" /* webpackChunkName: "component---src-pages-health-js" */),
  "component---src-pages-hospitals-jsx": () => import("./../../../src/pages/hospitals.jsx" /* webpackChunkName: "component---src-pages-hospitals-jsx" */),
  "component---src-pages-income-js": () => import("./../../../src/pages/income.js" /* webpackChunkName: "component---src-pages-income-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-life-js": () => import("./../../../src/pages/life.js" /* webpackChunkName: "component---src-pages-life-js" */),
  "component---src-pages-overview-js": () => import("./../../../src/pages/overview.js" /* webpackChunkName: "component---src-pages-overview-js" */),
  "component---src-pages-privacy-jsx": () => import("./../../../src/pages/privacy.jsx" /* webpackChunkName: "component---src-pages-privacy-jsx" */),
  "component---src-pages-services-jsx": () => import("./../../../src/pages/services.jsx" /* webpackChunkName: "component---src-pages-services-jsx" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-trauma-js": () => import("./../../../src/pages/trauma.js" /* webpackChunkName: "component---src-pages-trauma-js" */)
}

